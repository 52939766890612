<template>
  <div>
    <div class="card">
      <div class="nav-panel anan-tabs anan-tabs-line anan-tabs-normal anan-tabs-top">
        <div class="anan-tabs__nav">
          <div
            class="anan-tabs__nav-warp px-2"
            style="margin-top: 5px;"
          >
            <div
              class="anan-tabs__nav-tabs"
              style="transform: translateX(0px)"
            >
              <div
                class="anan-tabs__nav-tab"
                :class="{ active: isActive === 'all' }"
                style="white-space: normal"
                @click="getData(status = 0,search_val), isActive = 'all'"
              >
                <span> {{ $t('all') }} </span>
              </div>

              <div
                class="anan-tabs__nav-tab"
                :class="{ active: isActive === 'cancel' }"
                style="white-space: normal"
                @click="getData(status = 1,search_val), isActive = 'cancel'"
              >
                <span> {{ $t('cancel') }} </span>
              </div>

              <div
                class="anan-tabs__nav-tab"
                :class="{ active: isActive === 'waiting' }"
                style="white-space: normal"
                @click="getData(status = 2,search_val), isActive = 'waiting'"
              >
                <span> {{ $t('pendingReview') }} </span>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="d-lg-flex justify-content-between align-items-center px-2 mt-2">
        <h3 class="font-weight-bolder">
          {{ $t('reportMissingGoods') }}
        </h3>

        <div class="d-flex align-items-center">
          <div class="anan-input__inner anan-input__inner--normal">
            <input
              v-model="search_val"
              type="text"
              :placeholder="$t('search')"
              class="anan-input__input"
              @keyup.enter="getData(status,search_val)"
            >
            <div class="anan-input__suffix">
              <button
                type="button"
                class="anan-button anan-button--normal anan-button--primary"
                @click="getData(status,search_val)"
              >
                <i class="fal fa-search" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <b-overlay
        :show="showOver"
        variant="transparent"
        opacity="0.99"
        blur="5px"
        rounded="sm"
      >
        <template #overlay>
          <div class="text-center">
            <p id="cancel-label">
              กรุณารอสักครู่...
            </p>
          </div>
        </template>
        <div class="p-2">
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            show-empty
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner
                  label="กำลังโหลด..."
                  variant="danger"
                />
              </div>
            </template>
            <template #empty>
              <div class="text-center">
                <img
                  src="@/assets/images/anan-img/empty/empty.png"
                  alt="empty"
                  width="180px"
                >
                <p>No Data</p>
              </div>
            </template>

            <template #cell(created_at)="data">
              {{ time(data.item.created_at) }}
            </template>
            <template #cell(detail)="data">
              <span
                v-for="(item,index) in data.item.detail"
                :key="index"
              >
                {{ index+1 }}. {{ item.detail }} <br>
              </span>
            </template>
            <template #cell(username)="data">
              <b-link
                :to="{name : 'admin-employee-edit', params: {id: data.item.uid}}"
                class="mb-0"
              >
                {{ data.item.username }}
              </b-link>
            </template>
            <template #cell(itemnumber_text)="data">
              <b-link
                :to="{name : 'admin-lost-package-edit', params: {id: data.item._id}}"
                class="mb-0"
              >
                {{ data.item.itemnumber_text }}
              </b-link>
            </template>
            <template #cell(order_text)="data">
              <b-link
                :to="{name : 'admin-order-detail', params: {id: data.item.order_id}}"
                class="mb-0"
              >
                {{ data.item.order_text }}
              </b-link>
            </template>
            <template #cell(fileimg)="data">
              <span
                v-for="imagePr in data.item.fileimg"
                :key="imagePr"
                class="preview-image-container mr-25 d-flex justify-content-center"
              >
                <div class="preview-image-overlays p-0">
                  <img
                    :src="GetImg('lostpackage_file',imagePr)"
                    alt="asd"
                    class="rounded mr5"
                    style="max-width: 100%; height: auto;"
                    @click="showPicture(imagePr)"
                  >
                </div>
              </span>
            </template>
            <template #cell(refund)="data">
              <span
                v-for="(item,index) in data.item.detail"
                :key="index"
              >
                <span v-if="item.check_balance == 1"><i class="fas fa-history text-primary" /> </span>
                <span v-if="item.check_balance == 2"><i class="fas fa-check text-success" /></span>
                <span v-if="item.check_balance == 3"><i class="fas fa-ban text-danger" /></span>
                {{ item.balance }} ¥ <br>
              </span>
            </template>
            <template #cell(amount)="data">
              {{ data.item.detail.reduce((total,item) => total + item.balance,0) }} ¥
            </template>
            <template #cell(action)="data">
              <feather-icon
                icon="Edit3Icon"
                class="mr-1 text-primary cursor-pointer"
                @click="EditType(data.item)"
              />
            </template>

          <!-- <template #cell(number)="data">
            <b-link :to="{name: 'admin-user-type-edit', params: {id: data.item.id}}">
              GUSR-{{ items.length - (perPage * (currentPage - 1) + (data.index + 1)) + 1 }}
            </b-link>
          </template>

          <template #cell(index)="data">
            {{ perPage * (currentPage - 1) + (data.index + 1) }}
          </template>

          <template #cell(transactionDate)="data">
            {{ time(data.item.transactionDate) }}
          </template>

          <template #cell(amount)="data">
            {{ Commas(data.item.amount) }}฿
          </template>

          <template #cell(document)="data">
            <img
              :src="data.item.document"
              alt="img-document"
              class="border"
              height="40"
            >
          </template>

          <template #cell(status)="data">
            <span
              v-if="data.item.status === 'waiting'"
              class="badge badge-primary badge-pill"
            > รอตรวจสอบ </span>

            <span
              v-if="data.item.status === 'success'"
              class="badge badge-success badge-pill"
            > ดำเนินการสำเร็จ </span>
          </template>

          <template #cell(actions)="data">
            <feather-icon
              icon="Edit3Icon"
              class="mr-1 text-primary cursor-pointer"
              @click="EditType(data.item)"
            />

            <feather-icon
              icon="PrinterIcon"
              class="text-success cursor-pointer"
              @click="DeleteType(data.item)"
            />
          </template> -->
          </b-table>

          <div class="demo-spacing-0 d-flex justify-content-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
              @input="getData(status,search_val )"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </b-overlay>
    </div>
    <!-- modal -->
    <b-modal
      ref="my-modal"
      hide-footer
      title="รูปภาพสินค้า"
    >
      <img
        :src="pic"
        alt="..."
        class="img-fluid rounded"
      >

      <div class="text-center">
        <button
          type="button"
          class="btn btn-primary mt-2"
          @click="downloadImg"
        >
          Download
        </button>
      </div>
    </b-modal>

    <vue-image-lightbox-carousel
      ref="lightbox"
      :show="showLightbox"
      :images="images"
      :show-caption="false"
      @close="closeBox()"
    />
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone'
import {
  BPagination, BLink, BModal, BTable, BSpinner, BOverlay,
} from 'bootstrap-vue'
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'

export default {
  components: {
    BPagination,
    BLink,
    BModal,
    BTable,
    BSpinner,
    BOverlay,
    VueImageLightboxCarousel,
  },
  data() {
    return {
      images: [],
      showLightbox: false,
      search: '',
      isActive: 'all',
      selectedRadio: 'active',
      optionsRadio: [
        { text: 'All', value: 'all' },
        { text: 'Car', value: 'car' },
        { text: 'Ship', value: 'ship' },
      ],
      pageOptions: [5, 10, 15, 20],
      currentPage: 1,
      perPage: 7,
      totalRows: 1,
      general: 0,

      items: [
        // {
        //   id: 1,
        //   number: 'GUSR-0001',
        //   deliveryNumber: 'GUSR-0001',
        //   customer: 'GUSR-0001',
        //   transactionDate: 'GUSR-0001',
        //   approvalDate: '-',
        //   noun: 'บริษัท ชิดา คอมเมอร์เชียล (ประเทศไทย) จำกัด',
        //   document: 'https://plmcdn.azureedge.net/upload/images/receipt/7a796ac0.jpg',
        //   customerType: 'บุคคลธรรมดา',
        //   amount: 100000,
        //   status: 'waiting',
        // },

        // {
        //   id: 2,
        //   number: 'GUSR-0001',
        //   deliveryNumber: 'GUSR-0001',
        //   customer: 'GUSR-0001',
        //   transactionDate: 'GUSR-0001',
        //   approvalDate: '-',
        //   noun: 'บริษัท ชิดา คอมเมอร์เชียล (ประเทศไทย) จำกัด',
        //   document: 'https://plmcdn.azureedge.net/upload/images/receipt/72655903.jpg',
        //   customerType: 'นิติบุคคล',
        //   amount: 100000,
        //   status: 'success',
        // },
      ],
      pic: null,
      showOver: false,
      total: 0,
      notpaid: 0,
      waitingforcheck: 0,
      waitingforpayment: 0,
      paid: 0,
      OrderSuccess: 0,
      completetrack: 0,
      completewarehouse: 0,
      cancel: 0,
      status: 0,
      search_val: '',
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'itemnumber_text', label: this.$t('number'), thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'username', label: this.$t('customer'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'created_at', label: this.$t('transactionDate'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'order_text', label: this.$t('orderList'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'remark_admin', label: this.$t('mnemonicPhrase'), sortable: false, thStyle: { width: '15%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'detail', label: this.$t('details'), sortable: false, thStyle: { width: '20%' }, thClass: 'text-center',
        },
        {
          key: 'fileimg', label: this.$t('attachedFile'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'refund', label: this.$t('refundList'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'amount', label: this.$t('totalRefund'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-right',
        },
        {
          key: 'action', label: this.$t('manage'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },
  },
  watch: {
    '$route.query.querykey': {
      immediate: true,
      handler() {
        this.handleQueryChange()
      },
    },
  },
  mounted() {
    this.handleQueryChange()
  },
  methods: {
    handleQueryChange() {
      const { querykey } = this.$route.query
      if (querykey) {
        if (Number(querykey) === 2) {
          this.isActive = 'waiting'
          this.getData(this.status = 2, this.search_val)
        }
      } else {
        this.getData(this.status, this.search_val)
      }
    },
    // Commas(num) {
    //   return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    // },
    time(data) {
      return moment(data).format('DD/MM/YYYY HH:mm:ss')
    },
    GetNotify() {
      this.$http
        .get('/order/Notify')
        .then(response => {
          // console.log(response.data)
          this.total = response.data.total
          this.waitingforcheck = response.data.waitingforcheck
          this.waitingforpayment = response.data.waitingforpayment
          this.paid = response.data.paid
          this.OrderSuccess = response.data.OrderSuccess
          this.completetrack = response.data.completetrack
          this.completewarehouse = response.data.completewarehouse
          this.cancel = response.data.cancel
        })
        .catch(error => console.log(error))
    },
    GetImg(pathfile, filename) {
        return `${this.$http.defaults.baseURL}/viewimg?pathfile=${pathfile}/${filename}`
    },
    // eslint-disable-next-line camelcase
    getData(type, search_val) {
      this.showOver = true
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        type,
        search_val,
      }
      this.$http
        .get('/LostPackage/getData', { params })
        .then(response => {
          // console.log(response.data)
          // response.data.data.map(ele => { ele.fileimg = [] })
          this.items = response.data.data
          // this.totalRows = this.items.length
          this.totalRows = response.data.total
          this.showOver = false
          // this.ShowIMG()
        })
        .catch(error => {
          console.log(error)
          this.showOver = false
        })
    },
    async ShowIMG() {
      const Id = this.items.map(ele => ele._id)
      const params = {
        id: Id,
      }
      this.$http.get('/LostPackage/GetDataimg', { params })
        .then(response => {
          // console.log(response)
          response.data.forEach((data, index) => {
            this.items[index].fileimg = data.fileimg
          })
        })
        .catch(err => console.log(err))
    },

    EditType(data) {
      // eslint-disable-next-line no-underscore-dangle
      this.$router.push({ name: 'admin-lost-package-edit', params: { id: data._id } })
    },
    showPicture(picture) {
      this.showLightbox = true
      const img = { path: this.GetImg('lostpackage_file', picture) }
      this.images.push(img)
    },
    closeBox() {
      this.showLightbox = false
      this.images = []
    },
    resize(event) {
      // eslint-disable-next-line no-param-reassign
      event.target.style.height = 'auto'
      // eslint-disable-next-line no-param-reassign
      event.target.style.height = `${event.target.scrollHeight}px`
    },
    updateKeyword(id, note) {
      // console.log('Item ID:', id)
      // console.log('note:', note)
      const params = {
        id,
        note,
      }
      // console.log(params)
      this.$http
        .post('/order/updateNote', params)
      // eslint-disable-next-line no-unused-vars
        .then(response => {
          // console.log(response.data)
        })
        .catch(error => console.log(error))
    },
    showModal(data) {
      this.pic = data
      this.$refs['my-modal'].show()
    },
    downloadImg() {
      const imageURL = '@/assets/images/anan-img/example/1.jpg'
      const link = document.createElement('a')
      link.href = imageURL
      link.download = 'image.jpg'
      link.click()
    },
  },
}
</script>

      <style lang="scss" scoped></style>
